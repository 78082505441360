<template>
  <input
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    class="input"
    type="text" >
</template>

<script>
export default {
  props: ['modelValue'],
};
</script>

<style scoped>
.input {
  display: block;
  width: 100%;
  padding: 12px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  background-color: #ffffff;
  border: 2px dotted #fde8dc;
  border-radius: 8px;
  font-size: 16px;
}
</style>
