<template>
<div class="section section-plan">
  <div class="small-container">
    <h1 class="benefits-header">Información de cuenta</h1>
    <div class="account-data-container">
      <div class="account-data">
        <Label
          class="label"
          label="Email"
          v-slot='{ hideError }'
          :errors="getErrors('email')" >
          <Input type="email" v-model="payload.email" @input="hideError" />
        </Label>
        <Label
          class="label"
          label="Contraseña"
          v-slot='{ hideError }'
          :errors="getErrors('password')" >
          <Input type="password" v-model="payload.password" @input="hideError" />
        </Label>
        <Label
          class="label label-half"
          label="Nombre"
          v-slot='{ hideError }'
          :errors="getErrors('name')" >
          <Input v-model="payload.name" @input="hideError" />
        </Label>
        <Label
          class="label label-half"
          label="Apellido(s)"
          v-slot='{ hideError }'
          :errors="getErrors('lastName')" >
          <Input v-model="payload.lastName" @input="hideError" />
        </Label>
        <Label
          class="label"
          label="Teléfono"
          v-slot='{ hideError }'
          :errors="getErrors('phone')" >
          <Input v-model="payload.phone" @input="hideError" />
        </Label>
        <ActionButton buttonText="IR A PAGAR" @submit="submit" :isLoading="isLoading" />
      </div>
    </div>
  </div>
</div>
</template>

<script>
import ky from 'ky';
import ActionButton from '@/components/ActionButton.vue';
import Input from '@/components/Input.vue';
import Label from '@/components/Label.vue';

export default {
  components: {
    ActionButton,
    Input,
    Label,
  },
  data() {
    return {
      payload: {
        priceId: null,
        email: '',
        password: '',
        name: '',
        lastName: '',
        phone: '',
      },
      isLoading: false,
      errors: [],
      error: null,
    };
  },
  methods: {
    async submit() {
      this.isLoading = true;
      this.payload.email = this.payload.email.toLowerCase();
      try {
        const json = await ky.post(
          `${process.env.VUE_APP_BACKEND_URL}/register`,
          {
            json: this.payload,
          },
        ).json();
        window.location.href = json.url;
      } catch ({ response }) {
        await this.manageRequestErrors(response);
      } finally {
        this.isLoading = false;
      }
    },
    async manageRequestErrors(response) {
      if (response && response.status === 422) {
        const { errors } = await response.json();
        this.errors = errors;
      } else if (response && response.status === 400) {
        const { errorMessage } = await response.json();
        this.error = errorMessage;
      }
    },
    getErrors(property) {
      return this.errors.filter((el) => el.field === property);
    },
  },
  mounted() {
    this.payload.priceId = this.$route.query.planId;
    if (this.$route.query.email) {
      this.payload.email = this.$route.query.email;
    }
  },
};
</script>

<style lang="scss" scoped>
.section-plan {
  padding: 10px 0;
}
.account-data-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.account-data {
  width: 100%;
  max-width: 420px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.label {
  margin-bottom: 14px;
  width: 100%;
}
.label-half {
  width: 48%;
}
.label-text {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 6px;
}
</style>
